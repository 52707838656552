import React, { useState } from "react";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Contact, Popup } from "../../../components/popup";
import { QA } from "../../../data/data";
import OfferSlider from "../../../components/offer-slider";

const QaAndTesting = () => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setPopupShowContact(!popupShowContact);
  };
  return (
    <Layout>
      <div className="wrapper">
        <section className="serviceHeader qaSolutionsHeader">
          <div className="innerPageNavigation">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services/">Services</Link>
              </li>
              <li>
                <Link to="/services/qa-testing/">QA and Testing</Link>
              </li>
            </ul>
          </div>
          <div className="serviceContainer">
            <div className="heroContent">
              <h1>QA & Testing</h1>
              <p>
                Trusted by over 2000 startups and other businesses. An agile shop
                for excellence in Software Testing
              </p>
              <button className="btn heroBtn" onClick={handlePopupModalContact}>
                Get an estimate
              </button>
            </div>
            <div className="servicePartner">
              <ul>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/enterprise-solutions/">
                      Enterprise Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/mobility-solutions/">
                      Mobility Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/data-solutions/">
                      Data Solutions&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/cloud-solutions/">
                      Cloud Solutions
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/metaverse-development/">
                      Metaverse Development&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/startup-development/">
                      Startup Development&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/game-development/">
                      Game Development&nbsp;
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="serviceDetailsSection qaSolutions">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="serviceDetailsImage">
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="cardgradient"
                        src="../../../images/servicedetails/service-details-5.svg"
                      />
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="mobile-cardgradient"
                        src="../../../images/servicedetails/service-details-5.svg"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceContent">
                      <p>
                        At present all web/online businesses are on their toes
                        to make sure that they have an edge over their
                        competitors. To stay amongst this fierce competition
                        online/web business needs{" "}
                        <strong>
                          {" "}
                          to keep changing and adding more features rapidly to
                          their software product.
                        </strong>{" "}
                        And this, in turn, has changed the way new feature
                        updates and releases are done. These applications
                        operate in a very dynamic environment with rapid release
                        cycle modes.
                      </p>
                      <p>
                        This is where software testing comes into play ensuring
                        that{" "}
                        <strong>
                          {" "}
                          the application is reliable and able to operate
                          consistently under peak loads.
                        </strong>{" "}
                        The QA team will also make sure the potential release
                        version meets all the functional and performance
                        requirements before certifying the particular release.{" "}
                        <strong>
                          Our independent testing facility is manned by Test
                          Engineers with 7 + years of experience and our team
                          uses efficient and state-of-the-art defect management
                          tools.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="solutionSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    <span className="circle"></span>
                    <span className="titleName">Why choose Xminds for QA?</span>
                  </h1>
                  <div className="solutionPoint">
                    <div
                      className="valuePoint"
                      data-aos-delay="100"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="5"
                    >
                      <h5>01</h5>
                      <p>Access to state-of-the-art technology</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="15"
                    >
                      <h5>02</h5>
                      <p>Cost efficiency</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="20"
                    >
                      <h5>03</h5>
                      <p>Superior domain knowledge and expert QA team</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="25"
                    >
                      <h5>04</h5>
                      <p>Top-of-the-line security standards</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="30"
                    >
                      <h5>05</h5>
                      <p>
                        Focused specialization and rigorous testing protocols
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="qaBox"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="serviceofferSection noserviceSlider" id="qa">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>What we offer?
              </h1>
              <div className="testimonialService">
                <div className="serviceContainer">
                  <div className="authorRow">
                    <OfferSlider data={QA} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactSection serviceContactSeaction">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="contactRelative">
                <div className="contactTitle">
                  <div className="casestudySlider">
                    <h1>
                      <span className="circle"></span>
                      <span className="titleName">
                        We love to listen to your requirements
                      </span>
                    </h1>
                  </div>
                </div>
                <Contact
                  type="static"
                  handleChange={handlePopupModal}
                  key={[popupShow, popupShowContact]}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="ourofficeSection serviceOtherSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>Our other services
              </h1>
              <div className="serviceOtherContainer">
                <div className="row">
                  <div className="servicePartner">
                    <ul>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/enterprise-solutions/">
                            Enterprise Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/mobility-solutions/">
                            Mobility Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/data-solutions/">
                            Data Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/cloud-solutions/">
                            Cloud Solutions&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/metaverse-development/">
                            Metaverse&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/startup-development/">
                            Startup&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/game-development/">
                            Game Development&nbsp;
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
      <Popup
        show={popupShowContact}
        handleClose={handlePopupModalContact}
        body="contactForm"
      />
    </Layout>
  );
};

export const Head = () => <Seo title="Reliable Quality Assurance and Software Testing Services | Xminds" description=" Xminds provides the most reliable QA & Software testing services. We use different manual & automated testing methods to ensure maximum efficiency."
  url="https://www.xminds.com/services/qa-testing/" />;

export default QaAndTesting;
